var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-10"},[_c('v-toolbar',{staticClass:"my-5",attrs:{"dense":"","flat":""}},[_c('div',{staticClass:"text-h4 primary--text"},[_vm._v("การชำระเงิน")]),_c('v-col',{staticClass:"ml-10",attrs:{"cols":"2"}},[_c('v-select',{attrs:{"label":"ช่วงเวลา","items":_vm.timeRangeTypes,"outlined":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.timeRangeType),callback:function ($$v) {_vm.timeRangeType=$$v},expression:"timeRangeType"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.list,"loading":_vm.loading,"loading-text":_vm.$table_loading_text,"page":_vm.filter.page,"items-per-page":_vm.filter.item_per_page,"hide-default-footer":""},on:{"update:page":function($event){return _vm.$set(_vm.filter, "page", $event)},"click:row":_vm.edit},scopedSlots:_vm._u([{key:"item._id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.list.map(function(x) {return x._id; }).indexOf(item._id)+1+(_vm.filter.item_per_page*(_vm.filter.page-1)))+" ")]}},{key:"item.date_of_issue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date_of_issue,_vm.$formatDate))+" ")]}},{key:"item.product",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.map(function (e) { return e.name; }).join(','))+" ")]}},{key:"item.payment.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment.type == '0' ? 'รอชำระเงิน' : '')+" "+_vm._s(item.payment.type == '1' ? 'QR CODE' : '')+" "+_vm._s(item.payment.type == '2' ? 'ชำระผ่านบัตรเครดิต' : '')+" "+_vm._s(item.payment.type == '3' ? 'โอนผ่านเลขบัญชี' : '')+" ")]}},{key:"footer",fn:function(){return [_c('table-pagination-custom',{attrs:{"page":_vm.filter.page,"itemsPerPage":_vm.filter.item_per_page,"length":_vm.pageCount},on:{"change-page":_vm.onChangePage,"change-items-per-page":_vm.onChangeitemsPerPage}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }